<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-3 mb-3">
        <span class="text-muted">Section > </span><span>The Rest</span>
      </div>
    </div>
    <section
      id="iq-favorites"
      class="mt-4"
    >
      <div class="">
        <div class="row">
          <div class="col-sm-12 overflow-hidden">
            <div class="iq-main-header d-flex">
              <h4 class="main-title">Partners and Discounts</h4>
            </div>
            <div class="">
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between">
                  DO NOT SHARE this PDF with non-members! These vendors will be
                  checking to make sure you are a member of The DJ's Vault.
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/DJV_Discounts-and-Partners.pdf?alt=media&token=f3aa0676-47cb-4496-879e-cdf22cacfbce"
                    target="_blank"
                  >
                    <span class="text-primary mt-4">Download </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="iq-favorites"
      class="mt-4"
    >
      <div class="">
        <div class="row">
          <div class="col-sm-12 overflow-hidden">
            <div class="iq-main-header d-flex">
              <h4 class="main-title mt-4">Members Only Facebook Group</h4>
            </div>
            <ul class="list-group">
              <li class="list-group-item">
                <a
                  href="https://www.facebook.com/groups/thedjsvaultmembersonly"
                  target="_blank"
                ><i
                    class="fa fa-facebook  mr-1"
                    style="color: blue"
                  ></i>
                  <span>Click Here To Enter</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section
      id="iq-favorites"
      class="mt-4"
    >
      <div class="">
        <div class="row">
          <div class="col-sm-12 overflow-hidden">
            <div class="iq-main-header d-flex">
              <h4 class="main-title mt-4">Frequently Asked Questions (FAQ)</h4>
            </div>
            <div>
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="text-light text-large mt-4 mb-2 question">
                    <i class="fa fa-question-circle"></i>I'm unable to Login
                  </div>
                  Be sure to use the email address that you used to sign up for
                  The DJ’s Vault. Copy/Paste the password (with no spaces) into
                  the login screen. If this still doesn’t work, email <a
                    class="text-danger"
                    href="mailTo:help@thedjsvault.com"
                  >help@thedjsvault.com</a>
                  .
                </li>
                <li class="list-group-item">
                  <div class="text-light mt-4  mb-2 question">
                    <i class="fa fa-question-circle"></i>I signed up but didn’t
                    receive a login email
                  </div>
                  Check your spam folder. Seriously, this is usually the problem
                  or you entered an invalid email (which we can fix if you
                  contact us at <a
                    class="text-danger"
                    href="mailTo:help@thedjsvault.com"
                  >help@thedjsvault.com</a>).
                </li>
                <li class="list-group-item">
                  <div class="text-light mt-4 mb-2 question">
                    <i class="fa fa-question-circle"></i>How can I Be Removed
                    From Your Email or Text List?
                  </div>
                  Contact
                  <a
                    class="text-danger"
                    href="mailTo:help@thedjsvault.com"
                  >help@thedjsvault.com</a>
                  - Warning, if you mark our email as spam, you may be unable to
                  sign back up for The DJ’s Vault or make future purchases with
                  us at a later date. Our system literally blocks your email
                  address so we can never email you again.
                </li>
                <li class="list-group-item">
                  <div class="text-light mt-4 mb-2 question">
                    <i class="fa fa-question-circle"></i>How do I cancel my
                    membership?
                  </div>
                  Contact us at <a
                    class="text-danger"
                    href="mailTo:help@thedjsvault.com"
                  >help@thedjsvault.com</a>. Cancellations have to be manually processed in our system.

                </li>
                <li class="list-group-item">
                  <div class="text-light mt-4 mb-2 question">
                    <i class="fa fa-question-circle"></i>How do I cancel via
                    PayPal
                  </div>
                  If you attempt to cancel your membership through PayPal,
                  please let us know so we can cancel the re-billing in our
                  system. An automatic re-bill may still process.

                  If you cancel the rebill yourself inside of PayPal, you will lose access to the site immediately. Please email us to give you the rest of your time on your membership
                </li>
                <li class="list-group-item">
                  <div class="text-light mt-4 mb-2 question">
                    <i class="fa fa-question-circle"></i>Why was my membership
                    canceled?
                  </div>
                  If your payment does not go through on your renewal date, our
                  system will automatically attempt to process the payment a few
                  more times over the course of several days. On the last failed
                  attempt, your membership will automatically cancel. If you
                  were unable to update your payment information during that
                  time, simply sign back up or contact us at
                  <a
                    class="text-danger"
                    href="mailTo:help@thedjsvault.com"
                  >help@thedjsvault.com</a>
                  and we’ll help you re-join.
                </li>

                <li class="list-group-item">
                  <div class="text-light mt-4 mb-2 question">
                    <i class="fa fa-question-circle"></i>Can I call to update my
                    payment information?
                  </div>
                  To protect you, we cannot take credit card information over
                  the phone or by email. You will need to update this
                  information directly within The DJ’s Vault.
                </li>
                <li class="list-group-item">
                  <div class="text-light mt-4 mb-2 question">
                    <i class="fa fa-question-circle"></i>How Do I Change My
                    Billing Info for The DJ's Vault Monthly/Annual Payments
                  </div>

                  1.
                  <a
                    class="text-danger"
                    target="_blank"
                    href="https://thedjsvault.kartra.com/self_billing/FIE3ObpKkirK"
                  >Go here to open the self billing portal</a><br />

                  2. Type in your email you signed up for The DJ's Vault
                  with.<br />

                  3. Search your email for your last DJ's Vault invoice and
                  locate your "ORDER ID" aka Invoice ID. Type it in.<br />

                  4. Click "Locate Order." <br />

                  5. Change billing info. <br />
                  <br />
                  <a
                    class="text-danger"
                    target="_blank"
                    href="https://thedjsvault.kartra.com/help/billing-support/article/1/4"
                  >More information here</a>
                  <b><br /><br />For any other help, email us at
                    <a
                      class="text-danger"
                      href="mailTo:help@thedjsvault.com"
                    >help@thedjsvault.com</a>
                    and we'll assist you as soon as possible.</b>
                  <div class="text-light mt-4">
                    PLEASE NOTE: The help desk is not staffed 24/7. Submissions
                    to the help desk or support email may take up to 12 hours to
                    be addressed and we are off on the weekends. Thank you for
                    your patience.
                  </div>
                </li>
              </ul>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="iq-favorites"
      class="mt-4"
    >
      <div class="">
        <div class="row">
          <div class="col-sm-12 overflow-hidden">
            <div class="iq-main-header d-flex">
              <h4 class="main-title mt-4">Contact and Support</h4>
            </div>
            <div>
              <ul class="list-group">
                <li class="list-group-item">
                  Have questions? Suggestions for more content? Need to cancel?
                  Refund?<br /><br />

                  Contact the Billing & Support
                  <a
                    class="text-danger"
                    target="_blank"
                    href="https://thedjsvault.kartra.com/help/billing-support/"
                  >Help Desk Here</a>
                  and we'll assist you as soon as possible.

                  <br />
                  Click on "Support" at the top right & submit a ticket and
                  we'll help you ASAP (as shown below)<br />
                  <img
                    src="/images/helpkarta.png"
                    class="img-max"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
};
</script>

<style>
.list-group-item {
  background-color: #2c2c2c;
  color: #ffffff;
}

.img-max {
  max-width: 800px;
  width: 100%;
}

.question {
  font-size: 24px;
}

.question i {
  padding-right: 6px;
}
</style>
